<template>
    <b-overlay :show='showLoader'>
        <b-card class="suppliers">
            <div v-if="vlozisce" class="m-0 mt-1 w-100 d-flex justify-content-end" style="font-size: 13px;">
                <rounded-button style="line-height: 18px" @click="goBack()">
                    <feather-icon class="mr-1" size="1.2x" icon="ArrowLeftIcon" />
                    Nazaj
                </rounded-button>
            </div>
            <div class="p-1 mt-2" style="background-color: #72a5d8;">
                <h4 v-if="vlozisce" class="m-0 text-white">
                    Vlozišče: <span class="capitalize">{{ vlozisce.novo_ime }}</span>
                </h4>
                <h4 v-else class="m-0 text-white">
                    Glavno vlozišče
                </h4>
            </div>
            <div v-if="!vlozisce" class="my-portal-table mt-2">
                <b-table responsive striped :items="vlozisca" :fields="fields">
                    <template #cell(novo_ime)="data">
                        <div @click="openMap(data.item)" v-if="vlozisca && vlozisca.length > 0" style="cursor: pointer" class="pr-1 w-100"><feather-icon class="mr-1" size="1.2x" icon="FolderIcon" /> {{data.item.novo_ime}}</div>
                    </template>
                    <template v-if="vlozisca.length === 0" slot="bottom-row">
                        <td>
                            <span class="d-flex justify-content-center">Ni podatkov za prikaz</span>
                        </td>
                    </template>
                </b-table>
            </div>
            <div v-else class="my-portal-table mt-2">
                <b-table responsive striped  :items="files" :fields="fields_doc">
                    <template #cell(actions)="data">
                        <a @click="downloadDoc(data.item)">
                            <feather-icon class="mr-1" size="1.5x" icon="DownloadIcon" />
                        </a>
                    </template>
                    <template v-if="files.length === 0" slot="bottom-row">
                        <td :colspan="fields_doc.length">
                            <span class="d-flex justify-content-center">Ni podatkov za prikaz</span>
                        </td>
                    </template>
                </b-table>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
    import {BCard, BOverlay, BTable} from 'bootstrap-vue'
    import RoundedButton from '@/views/Components/RoundedButton'
    import dayjs from 'dayjs'
    export default {
        components: {BOverlay, BCard, BTable, RoundedButton},
        data() {
            return {
                fields: [{ key: 'novo_ime', label: 'Dokumenti upravnika', sortable: false, class: 'text-left' }],
                fields_doc:[
                    {key:'zadeva', label:'Naziv', sortable: true},
                    {key:'partner_naziv', label:'Naziv Partnerja', sortable: true},
                    {key:'datum_dokumenta', label:'Datum', sortable: true},
                    { key: 'actions', label: '', sortable: false}
                ],
                vlozisca:[],
                files:[],
                vlozisce:null,
                showLoader:false
            }
        },
        methods:{
            async loadData() {
                this.showLoader = true
                try {
                    this.vlozisca = []
                    await this.loadVlozisca()
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            async goBack() {
                this.vlozisce = null
            },
            async openMap(map) {
                this.vlozisce = map
                this.files = []
                await this.loadMapFiles()
            },
            downloadDoc(doc) {
                const thisIns = this
                this.showLoader = true
               
                this.$http({
                    url: `/api/iiportal-user/v1/attachements/dokumenti/${this.vlozisce.id_vlozisca}/${doc.id}`, //your url
                    method: 'GET',
                    responseType: 'blob' // important
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', `${doc.zadeva}.${doc.format.toLowerCase()}`) //or any other extension
                        document.body.appendChild(link)
                        link.click()
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju pdf je prišlo do napake!\n${error.message}`)
                    }).finally(() => {
                        thisIns.showLoader = false
                    })
            },
            async loadVlozisca() {
                const res = await this.$http.get('api/iiportal-user/v1/e_manager/dokumenti')
                this.vlozisca = res.data
            },
            async loadMapFiles() {
                this.showLoader = true
                try {
                    const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/dokumenti/${this.vlozisce.id_vlozisca}`)
                    this.files = res.data
                    this.files.forEach((x) => {
                        x.datum_dokumenta = dayjs(x.datum_dokumenta).format('DD.MM.YYYY')
                    })
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style>
.my-portal-table .table th {
    background-color: #dbe9f5 !important;
    border-top: none;
    border-bottom: none;
    text-transform: none;
    padding-top: 10px !important;
    padding-bottom: 20px !important;
}
.my-portal-table .table thead tr {
    border-bottom: 2px solid #72a5d8;
    border-right: 30px solid #dbe9f5 !important;
    border-left: 30px solid #dbe9f5 !important;
    color: #72a5d8 !important;
    font-weight: bold;
    background: #dbe9f5 !important;
}
.my-portal-table .table tbody tr {
    background-color: #eaf3f9 !important;
    border-top: 1px solid #72a5d87e;
    border-right: 30px solid #eaf3f9;
    border-left: 30px solid #eaf3f9;
}
.my-portal-table .table tfoot {
    background-color: #eaf3f9 !important;
    border-top: 2px solid #72a5d87e;
    border-right: 30px solid #eaf3f9;
    border-left: 30px solid #eaf3f9;
    border-bottom: 20px solid #eaf3f9;
    font-weight: bold;
}
</style>